import { XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, DialogActions, DialogContent, IconButton, Slide, SvgIcon } from '@mui/material'
import React from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function DownloadAppDialog({
    open,
    handleClose,
}) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            aria-describedby="payment-dialog"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                        handleClose()
                    }}
                >
                    <SvgIcon fontSize='small'>
                        <XMarkIcon />
                    </SvgIcon>
                </IconButton>
            </DialogActions>
            <DialogContent>
                <a
                    href='https://onelink.to/duuap4'
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        alt='Download'
                        src='/assets/images/download_app_image.png'
                        style={{ width: "100%", cursor: "pointer" }}
                    />
                </a>
            </DialogContent>
        </Dialog>
    )
}

export default DownloadAppDialog