import React, { useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import ContentsList from '../../components/Contents/ContentsList'
import { Box, Container, useMediaQuery } from '@mui/material'
import { addTrackIDUrl, getAllContentUrl } from '../../seed/url'
import DownloadAppDialog from '../../components/Shared/DownloadAppDialog'
import { useLocation } from 'react-router-dom'
import { postRequest } from '../../services/api-service'
import { publicIp } from 'public-ip';

function Home() {
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const [openDownloadAppDialog, setOpenDownloadAppDialog] = React.useState(false);
    const location = useLocation();

    const handleOpenDownloadAppDialog = () => {
        setOpenDownloadAppDialog(true)
    }

    const handleCloseDownloadAppDialog = () => {
        setOpenDownloadAppDialog(false)
    }

    const recordTracker = async () => {
        // Create a new URLSearchParams object with the query string
        const params = new URLSearchParams(location.search);

        // Get the value of the 'tracker' query parameter
        const trackerParam = params.get("tracker");

        if (trackerParam) {
            let ipAdress = "";
            try {
                ipAdress = await publicIp();
            } catch (error) {
                ipAdress = "";
            }
            postRequest(
                addTrackIDUrl,
                {
                    ip_address: ipAdress,
                    tracking_id: trackerParam
                },
                (data) => { },
                (error) => { }
            )
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            handleOpenDownloadAppDialog();
        }, 180000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Layout>
            {mdUp ?
                <Container sx={{ mt: 3 }}>
                    <ContentsList
                        url={getAllContentUrl}
                        body={{
                            published: "YES",
                            platform: "MIN_WEB_APP",
                        }}
                        recordTracker={recordTracker}
                    />
                </Container>
                :
                <>
                    {openDownloadAppDialog &&
                        <DownloadAppDialog
                            open={openDownloadAppDialog}
                            handleClose={handleCloseDownloadAppDialog}
                        />
                    }
                    <Box sx={{ mt: 3 }}>
                        <ContentsList
                            url={getAllContentUrl}
                            body={{
                                published: "YES",
                                platform: "MIN_WEB_APP",
                            }}
                            recordTracker={recordTracker}
                        />
                    </Box>
                </>
            }
        </Layout>
    )
}

export default Home